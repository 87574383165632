import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Sponsors from "../components/sponsors"

export const query = graphql`
  query images {
    heroBanner: contentfulAsset(title: { eq: "hero-banner" }) {
      file {
        url
      }
      title
    }
    cancerInstituteOne: contentfulAsset(
      title: { eq: "national-cancer-institute" }
    ) {
      fluid {
        src
      }
      title
    }
    cancerInstituteTwo: contentfulAsset(
      title: { eq: "national-cancer-institute-2" }
    ) {
      fluid {
        src
      }
      title
    }
    sponsors: allContentfulAsset(filter: { title: { glob: "sponsor-*" } }) {
      edges {
        node {
          title
          fluid {
            src
          }
        }
      }
    }
  }
`

const Home = ({ data }) => {
  const { heroBanner, cancerInstituteOne, cancerInstituteTwo, sponsors } = data
  return (
    <Layout>
      <div className="home">
        <section
          style={{ backgroundImage: `url("${heroBanner.file.url}")` }}
          className="hero is-medium"
        >
          <div className="hero-body">
            <div className="container">
              <h1 className="title is-1">Sirtuin Discovery</h1>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container has-text-centered">
            <p className="content has-text-justified is-size-5">
              The response to genotoxic or metabolic stress conditions has a
              major impact on the maintenance of genome integrity and is
              intimately linked to the development of many human pathologies and
              aging. The onset and development of different types of cancers,
              such as haematological malignancies, are strongly influenced by
              this response which makes it a promising therapeutical approach.
              The members of the sirtuin family of NAD<sup>+</sup> - dependent
              enzymes are key coordinators of this response as they play an
              important role in the crosstalk between environment and the
              genome, at both a cellular and a physiological level.
              Particularly, they have a crucial role in the maintenance of
              genome stability, epigenetics, metabolic homeostasis, cell
              differentiation and development. Our main goal is to define the
              epigenetic mechanisms ruling this sirtuin-dependent response and
              their functional implications in genome stability, cancer, and
              aging. For that purpose, we employ a multidisciplinary approach
              that ranges from in vitro studies to in vivo mouse models and
              combines Biochemistry, Genetics and Molecular and Cellular
              Biology.
            </p>
            <div className="columns mt-5">
              <div className="column">
                <figure className="image is-5by3">
                  <img
                    className="cover"
                    src={cancerInstituteOne.fluid.src}
                    alt="DNA"
                  />
                </figure>
              </div>
              <div className="column">
                <figure className="image is-5by3">
                  <img
                    className="cover"
                    src={cancerInstituteTwo.fluid.src}
                    alt="DNA"
                  />
                </figure>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <hr />
            <Link to="/publications">
              <h4 className="title is-4">Recent Publications</h4>
            </Link>
            <hr />
            <Link to="/news">
              <h4 className="title is-4">Latest news</h4>
            </Link>
            <hr />
          </div>
        </section>
        <section className="section">
          <div className="container">
            <h4 className="title is-4 mb-6">Our sponsors</h4>
            <div className="content">
              <Sponsors icons={sponsors.edges} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Home
