import React from "react"

const Sponsors = ({ icons }) => (
  <div className="columns is-multiline is-mobile is-centered">
    {icons.map(({ node }) => (
      <div key={node.title} className="column is-half-mobile">
        <figure className="image is-128x128">
          <img src={node.fluid.src} alt="sponsor" />
        </figure>
      </div>
    ))}
  </div>
)

export default Sponsors
